import React from 'react';
import '../css/Contact.css';

function Contact() {
  return (
    <section id="contact" className="contact-section">
      <h2>Contact Us</h2>
      <h3>MEEUM</h3>
      <p>meeum0831@gmail.com</p>
      <p>(57203) 16, Deokgok-gil, Bugi-myeon, Jangseong-gun, Jeollanam-do, Republic of Korea
      </p>
      <p>Phone: (010) 9642-1345</p>
    </section>
  );
}

export default Contact;
